export interface HeaderTextProps {
  size?: 'small' | 'medium' | 'large';
}

const handleClick = () => {
  window.location.href = '/';
};

/**
 * Primary UI component for user interaction
 */
export const HeaderText = ({ size = 'medium' }: HeaderTextProps) => {
  const sizes = {
    small: '32px',
    medium: '64px',
    large: '128px',
  };

  return (
    <h2
      className="headerText"
      style={{ height: sizes[size] }}
      onClick={handleClick}
    >
      welcome to jeremylcook.com
    </h2>
  );
};
