import potBoilingSvg from './assets/pot-boiling.svg?url';

export interface LogoProps {
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
}

const handleClick = () => {
  window.location.href = '/';
};

/**
 * Primary UI component for user interaction
 */
export const Logo = ({ size = 'medium' }: LogoProps) => {
  const sizes = {
    small: '32px',
    medium: '64px',
    large: '128px',
  };

  return (
    <img
      src={potBoilingSvg}
      className="logo"
      alt="alt"
      style={{ width: sizes[size] }}
      onClick={handleClick}
    />
  );
};
