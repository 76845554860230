import './App.css';
import { WelcomeHeader } from './stories/WelcomeHeader';

function App() {
  return (
    <div>
      {/*<div>
        <Page />
      </div>*/}
      <div>
        <WelcomeHeader size="medium" />
      </div>
    </div>
  );
}

export default App;
