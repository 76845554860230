import { HeaderText } from './HeaderText';
import { Logo } from './Logo';

export interface WelcomeHeaderProps {
  size?: 'small' | 'medium' | 'large';
}

/**
 * Primary UI component for user interaction
 */
export const WelcomeHeader = ({ size = 'medium' }: WelcomeHeaderProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Logo size={size} />
      <HeaderText size="medium" />
    </div>
  );
};
